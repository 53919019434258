import React, { useRef } from 'react';
import { IReportTemplateField } from '../report-template-field.interface';
import {
  MenuButtonBold,
  MenuButtonItalic,
  MenuControlsContainer,
  MenuDivider,
  MenuSelectHeading,
  RichTextEditor,
  type RichTextEditorRef,
} from 'mui-tiptap';
import StarterKit from '@tiptap/starter-kit';

type Props = {
  field: IReportTemplateField;
};

export const ReportTemplateFieldRTE: React.FunctionComponent<Props> = ({ field }) => {
  const rteRef = useRef<RichTextEditorRef>(null);
  return (
    <RichTextEditor
      ref={rteRef}
      extensions={[StarterKit]} // Or any Tiptap extensions you wish!
      content={field.defaultValue as string} // Initial content for the editor
      // Optionally include `renderControls` for a menu-bar atop the editor:
      renderControls={(): JSX.Element => (
        <MenuControlsContainer>
          <MenuSelectHeading />
          <MenuDivider />
          <MenuButtonBold />
          <MenuButtonItalic />
          {/* Add more controls of your choosing here */}
        </MenuControlsContainer>
      )}
    />
  );
};
