import { Describe, StructError, assert, boolean, enums, nullable, number, object, pattern, string } from 'superstruct';
import { handleFailure } from '@utils/superstruct/handle-failure';
import { IAppointmentSummary } from '@features/appointment-summaries/appointment-summary.interface';
import { AppointmentSummaryResponse } from '../api';
import { ISODateTimeRegexp } from '@utils/dates/iso-string.type';
import { AssessmentMethods } from '@features/order/needs-assessment/assessment-method.type';

const AppointmentSummaryStruct: Describe<IAppointmentSummary> = object({
  id: number(),
  orderId: number(),
  assessmentMethod: nullable(enums(Object.values(AssessmentMethods))),
  assessorId: nullable(number()),
  assessmentCentreId: nullable(number()),
  appointmentDate: nullable(pattern(string(), ISODateTimeRegexp)),
  isCancelled: boolean(),
  isPaid: boolean(),
  customerId: nullable(number()),
  funderInvoice: nullable(string()),
  supplierInvoice: nullable(string()),
  customerFullName: string(),
  customerCrn: nullable(string()),
  organisationId: nullable(number()),
  assessmentType: nullable(string()),
  orderStatusId: nullable(number()),
});

export function validateFilterAppointmentSummary(response: AppointmentSummaryResponse, url: string): void {
  for (const entry of response.entries) {
    try {
      assert(entry, AppointmentSummaryStruct);
    } catch (error) {
      for (const failure of (error as StructError).failures()) {
        handleFailure(failure, url);
      }
    }
  }
}
