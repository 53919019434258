export const RouteParams = {
  customerId: 'customerIdString',
  tutorId: 'tutorIdString',
  orderId: 'orderIdString',
  productId: 'productIdString',
  quoteId: 'quoteIdString',
  assessorId: 'assessorIdString',
  assessmentCentreId: 'assessmentCentreIdString',
  hepId: 'hepIdString',
  reportSlug: 'reportSlugString',
} as const;

type Keys = keyof typeof RouteParams;

export type RouteParamsType = (typeof RouteParams)[Keys];
