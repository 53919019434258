export type ReportLink = {
  title: string;
  slug: string;
  sections: ReportLink[];
};

export const reportNavigation: ReportLink[] = [
  {
    title: 'Title Page',
    slug: 'title',
    sections: [
      {
        title: 'Student Information',
        slug: 'student-information',
        sections: [],
      },
      {
        title: 'Assessment Information',
        slug: 'assessment-information',
        sections: [],
      },
      {
        title: 'Course Information',
        slug: 'course-information',
        sections: [],
      },
      {
        title: 'Statement of Aims',
        slug: 'statement-of-aims',
        sections: [],
      },
    ],
  },
  {
    title: 'A. Summary',
    slug: 'summary',
    sections: [
      {
        title: 'A-1. List of Recommended Support',
        slug: 'a-1-list-of-recommended-support',
        sections: [],
      },
      {
        title: 'A-2. Student Summary',
        slug: 'a-2-student-summary',
        sections: [],
      },
    ],
  },
  {
    title: 'B. Background Information',
    slug: 'background-information',
    sections: [
      {
        title: 'B-1. Disability information',
        slug: 'b-1-disability-information',
        sections: [],
      },
      {
        title: 'B-2. Details of previous DSA',
        slug: 'b-2-details-of-previous-dsa',
        sections: [],
      },
      {
        title: 'B-3. Disability Information',
        slug: 'b-3-disability-information',
        sections: [],
      },
      {
        title: 'B-4. Higher education provider support',
        slug: 'b-4-higher-education-provider-support',
        sections: [],
      },
    ],
  },
  {
    title: 'C. Effects of Disability and Recommendations',
    slug: 'effects-of-disability-and-recommendations',
    sections: [
      {
        title: 'C-1. Strategies/equipment previously used/currently in use',
        slug: 'c-1-strategies-equipment-previously-used-currently-in-use',
        sections: [],
      },
      {
        title: 'C-2. Impacts of disability and recommended strategies',
        slug: 'c-2-impacts-of-disability-and-recommended-strategies',
        sections: [
          {
            title: 'C-2-1. Research and reading',
            slug: 'c-2-1-research-and-reading',
            sections: [],
          },
          {
            title: 'C-2-2. Writing and reviewing academic work',
            slug: 'c-2-2-writing-and-reviewing-academic-work',
            sections: [],
          },
          {
            title: 'C-2-3. Note-taking in lectures and seminars',
            slug: 'c-2-3-note-taking-in-lectures-and-seminars',
            sections: [],
          },
          {
            title: 'C-2-4. Managing time and organising work',
            slug: 'c-2-4-managing-time-and-organising-work',
            sections: [],
          },
          {
            title: 'C-2-5. Access to and use of technology',
            slug: 'c-2-5-access-to-and-use-of-technology',
            sections: [],
          },
          {
            title: 'C-2-6. Practical sessions, placements, field trips and additional course activities',
            slug: 'c-2-6-practical-sessions,-placements,-field-trips-and-additional-course-activities',
            sections: [],
          },
          {
            title: 'C-2-7. Examinations and timed assessments',
            slug: 'c-2-7-examinations-and-timed-assessments',
            sections: [],
          },
          {
            title: 'C-2-8. Social interaction and communication',
            slug: 'c-2-8-social-interaction-and-communication',
            sections: [],
          },
          {
            title: 'C-2-9. Travel and access to higher education environment',
            slug: 'c-2-9-travel-and-access-to-higher-education-environment',
            sections: [],
          },
          {
            title: 'C-2-10. Additional information',
            slug: 'c-2-10-additional-information',
            sections: [],
          },
        ],
      },
    ],
  },
  {
    title: 'D. Costs',
    slug: 'costs',
    sections: [
      {
        title: 'D-1. Equipment (Quote breakdown in Appendix 1)',
        slug: 'd-1-equipment-quote-breakdown-in-appendix-1',
        sections: [],
      },
      {
        title: 'D-2. Assistive Technology Training',
        slug: 'd-2-assistive-technology-training',
        sections: [],
      },
      {
        title: 'D-3. Non-Medical Help',
        slug: 'd-3-non-medical-help',
        sections: [],
      },
      {
        title: 'D-4. General Support',
        slug: 'd-4-general-support',
        sections: [],
      },
      {
        title: 'D-5. Needs Assessment Fee',
        slug: 'd-5-needs-assessment-fee',
        sections: [],
      },
      {
        title: 'D-6. Accommodation',
        slug: 'd-6-accommodation',
        sections: [],
      },
      {
        title: 'D-7. Travel - Taxi costs',
        slug: 'd-7-travel-taxi-costs',
        sections: [],
      },
      {
        title: 'D-8. Travel - Mileage costs',
        slug: 'd-8-travel-mileage-costs',
        sections: [],
      },
      {
        title: 'D-9. Needs assessor requests/preferred supplier',
        slug: 'd-9-needs-assessor-requests-preferred-supplier',
        sections: [],
      },
      {
        title: 'D-10. Summary of costs',
        slug: 'd-10-summary-of-costs',
        sections: [],
      },
    ],
  },
  {
    title: 'E. Higher Education Provider Support',
    slug: 'higher-education-provider-support',
    sections: [
      {
        title: 'E-1. Higher education provider support',
        slug: 'e-1-higher-education-provider-support',
        sections: [],
      },
      {
        title: 'E-2. Examinations and assessment',
        slug: 'e-2-examinations-and-assessment',
        sections: [],
      },
      {
        title: 'E-3. Other advice and guidance',
        slug: 'e-3-other-advice-and-guidance',
        sections: [],
      },
    ],
  },
  { title: 'F. Contact Details', slug: 'contact-details', sections: [] },
  { title: 'Appendix 1: Equipment Quote', slug: 'appendix-1-equipment-quote', sections: [] },
];
