import React from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import { RouteParamsType } from '@routes/route-params.type';
import { NavLink, useParams } from 'react-router-dom';
import { navigationMap } from './navigation-map';
import { Routes } from '@routes/routes';
import { useReportTemplate } from './use-report-template';
import { AppDetails, DetailsConfig, defaultRender } from '@components/AppDetails';
import { IReportTemplateField } from './report-template-field.interface';
import { ReportTemplateField } from './fields/ReportTemplateField';

const tempConfig: DetailsConfig<IReportTemplateField> = {
  fieldName: { label: 'Name' },
  fieldLabel: { label: 'Label' },
  fieldType: { label: 'Type' },
  defaultValue: {
    label: 'Default Value',
    render: (value) => {
      if (typeof value === 'string') {
        return <div dangerouslySetInnerHTML={{ __html: value }} />;
      }
      return defaultRender(value);
    },
  },
};

export const ReportSection: React.FunctionComponent = () => {
  const { reportSlugString } = useParams<RouteParamsType>();

  const slug = reportSlugString as string;

  const { template } = useReportTemplate(slug!);

  const link = reportSlugString ? navigationMap[reportSlugString] : null;
  if (!link || !template) {
    return <span>Error</span>;
  }

  return (
    <Stack direction="column" sx={{ height: '100%' }}>
      <Typography variant="h4" component="h1">
        {template.title}
      </Typography>
      {template.fields.map((field, index) => (
        <ReportTemplateField key={index.toString()} field={field} />
      ))}
      <Stack direction="row" sx={{ marginTop: 'auto' }}>
        {link.prev && (
          <NavLink to={`${Routes.report}/${link.prev}`} className="report-navigation-link">
            <Button variant="outlined">Prev</Button>
          </NavLink>
        )}
        {link.next && (
          <NavLink
            to={`${Routes.report}/${link.next}`}
            style={{ marginLeft: 'auto' }}
            className="report-navigation-link"
          >
            <Button variant="contained">Confirm and Next</Button>
          </NavLink>
        )}
      </Stack>
    </Stack>
  );
};
