import React from 'react';
import { IReportTemplateField } from '../report-template-field.interface';
import { ReportTemplateFieldTypes } from '../report-template-field-type.type';
import { ReportTemplateFieldAutoPopulated } from './ReportTemplateFieldAutoPopulated';
import { ReportTemplateFieldRTE } from './ReportTemplateFieldRTE';

type Props = {
  field: IReportTemplateField;
};

export const ReportTemplateField: React.FunctionComponent<Props> = ({ field }) => {
  switch (field.fieldType) {
    case ReportTemplateFieldTypes.AutoPopulated:
      return <ReportTemplateFieldAutoPopulated field={field} />;
    case ReportTemplateFieldTypes.RTE:
      return <ReportTemplateFieldRTE field={field} />;
  }
};
