import React from 'react';
import { TextField } from '@mui/material';
import { IReportTemplateField } from '../report-template-field.interface';

type Props = {
  field: IReportTemplateField;
};

export const ReportTemplateFieldAutoPopulated: React.FunctionComponent<Props> = ({ field }) => {
  return <TextField value={field.defaultValue} label={field.fieldLabel} />;
};
