import React, { useState } from 'react';
import { Typography } from '@mui/material';
import { GridColDef, GridRowParams, GridSortModel } from '@mui/x-data-grid';
import { AppDataGrid } from '@components/AppDataGrid';
import { AppModal } from '@components/AppModal';
import { BackendLabel } from '@features/backend-label/BackendLabel';
import { createColumnFactory } from '@utils/create-column.factory';
import { formatDate } from '@utils/dates/format-date';
import { renderEnum } from '@utils/render-enum';
import { IActionRecord } from './action-record.interface';
import { RecordTypeOptions } from './record-type.type';
import { WayOptions } from './way.type';
import { HookResult } from './use-progress-records';
import { usePagination } from '@features/pagination/use-pagination';
import { PageSizeNames } from '@features/pagination/page-size-names';

const createLogColumn = createColumnFactory<IActionRecord>();

const columns: GridColDef[] = [
  createLogColumn('createdAt', {
    headerName: 'Created',
    width: 150,
    renderCell: (params) => formatDate(params.row.createdAt, 'DD/MM/YYYY HH:mm'),
  }),
  createLogColumn('way', {
    headerName: 'Way',
    width: 60,
    renderCell: ({ row }) => renderEnum(row.way, WayOptions),
  }),
  createLogColumn('recordType', {
    headerName: 'Type',
    width: 80,
    renderCell: ({ row }) => renderEnum(row.recordType, RecordTypeOptions),
  }),
  createLogColumn('userId', {
    headerName: 'User',
    width: 160,
    renderCell: ({ row }) => <BackendLabel value={row.userId} optionKey="userOptions" />,
  }),
  createLogColumn('details', { headerName: 'Details', flex: 1 }),
];

const sortingModel: GridSortModel = [
  {
    field: 'createdAt',
    sort: 'desc',
  },
];

type Props = {
  useEntries: (params: URLSearchParams, count: number, skip: number) => HookResult;
};

export const ActionRecords: React.FC<Props> = ({ useEntries }) => {
  const { entries, totalEntries, paginationModel, onPaginationModelChange, isLoading } = usePagination(
    useEntries,
    PageSizeNames.ActionRecord,
  );

  const [currentRecord, setCurrentRecord] = useState<IActionRecord | null>(null);
  const openRecord = ({ row }: GridRowParams<IActionRecord>): void => setCurrentRecord(row);
  const closeRecord = (): void => setCurrentRecord(null);

  const recordTitle = currentRecord
    ? `${currentRecord.userId} ${formatDate(currentRecord.createdAt, 'DD/MM/YYYY HH:mm')}`
    : '';

  return (
    <>
      <AppDataGrid
        rows={entries}
        columns={columns}
        loading={isLoading}
        sx={{ height: '320px', mt: 1 }}
        hideFooter={false}
        paginationMode="server"
        rowCount={totalEntries}
        paginationModel={paginationModel}
        onPaginationModelChange={onPaginationModelChange}
        onRowClick={openRecord}
        sortModel={sortingModel}
      />
      <AppModal open={Boolean(currentRecord)} onClose={closeRecord} title={recordTitle}>
        <Typography>{currentRecord?.details}</Typography>
      </AppModal>
    </>
  );
};
