import React, { useState } from 'react';
import { Box, Button, Stack, Typography } from '@mui/material';
import { GridPagination, GridRowSelectionModel, GridSelectedRowCount } from '@mui/x-data-grid';
import { AppDataGrid } from '@components/AppDataGrid';
import { AppPaper } from '@components/AppPaper';
import { PageSizeNames } from '@features/pagination/page-size-names';
import { usePagination } from '@features/pagination/use-pagination';
import { QuickFilters } from '@features/quick-filter-tabs/QuickFilters';
import { QuickFilterTabsTypes } from '@features/quick-filter-tabs/quick-filter-tabs.type';
import { renderMoney } from '@utils/render-money';
import { AppDrawer } from '@components/AppDrawer';
import { useAppTitle } from '@hooks/use-app-title';
import { useToggle } from '@hooks/use-toggle';
import { sessionInvoiceColumns } from './session-invoice-columns';
import { useFilteredSessionInvoiceSummaries } from './filter-session-invoice-summaries/use-filtered-session-invoice-summaries';
import { FilterSessionInvoiceSummaries } from './filter-session-invoice-summaries/FilterSessionInvoiceSummaries';
import { AddSessionSupplierInvoice } from './AddSessionSupplierInvoice';
import { GenerateSessionXeroInvoices } from './GenerateSessionXeroInvoices';
import { SendSessionEInvoices } from './SendSessionEInvoices';

export const BulkSessionInvoicingPage: React.FC = () => {
  const [selection, setSelection] = useState<GridRowSelectionModel>([]);
  const [isQuickFilterActionAllowed, setIsQuickFilterActionAllowed] = useState(false);
  const [isAddSupplierModalOpen, toggleAddSupplierModal] = useToggle(false);
  const [isXeroModalOpen, toggleXeroModal] = useToggle(false);
  const [isSendEInvoiceModalOpen, toggleSendEInvoiceModal] = useToggle(false);
  useAppTitle('Bulk Session Invoicing');
  const { entries, totalEntries, paginationModel, onPaginationModelChange, isLoading } = usePagination(
    useFilteredSessionInvoiceSummaries,
    PageSizeNames.SessionInvoiceSummary,
  );

  const isButtonDisabled = !isQuickFilterActionAllowed || selection.length === 0;

  const selectedRows = entries.filter((item) => selection.includes(item.id));
  const totalGrossCost = selectedRows.reduce((a, c) => a + c.hourlyRate * c.duration, 0);

  return (
    <AppPaper sx={{ display: 'flex', flexDirection: 'column' }}>
      <Typography variant="h4" component="h1">
        Finance Dashboard — Sessions Invoicing
      </Typography>
      <QuickFilters onChange={setIsQuickFilterActionAllowed} type={QuickFilterTabsTypes.Sessions}>
        <FilterSessionInvoiceSummaries />
      </QuickFilters>
      <AppDataGrid
        rows={entries}
        columns={sessionInvoiceColumns}
        loading={isLoading}
        checkboxSelection={isQuickFilterActionAllowed}
        onRowSelectionModelChange={setSelection}
        rowSelectionModel={selection}
        disableRowSelectionOnClick={!isQuickFilterActionAllowed}
        fullHeight
        hideFooter={false}
        paginationMode="server"
        rowCount={totalEntries}
        paginationModel={paginationModel}
        onPaginationModelChange={onPaginationModelChange}
        slots={{
          footer: () => (
            <Stack
              direction="row"
              sx={{ borderTop: '1px solid rgb(224, 224, 224)', px: 2, py: 1 }}
              spacing={2}
              alignItems="center"
            >
              <Button onClick={toggleAddSupplierModal} disabled={isButtonDisabled} variant="outlined">
                Add Supplier Invoice
              </Button>
              <Button onClick={toggleXeroModal} disabled={isButtonDisabled} variant="outlined">
                Generate Xero Invoices
              </Button>
              <Button onClick={toggleSendEInvoiceModal} disabled={isButtonDisabled} variant="outlined">
                Send E-Invoices
              </Button>
              {selection.length > 0 && (
                <>
                  <GridSelectedRowCount selectedRowCount={selection.length} />
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>{`${renderMoney(totalGrossCost)} Total`}</Box>
                </>
              )}
              <GridPagination sx={{ ml: 'auto !important' }} />
            </Stack>
          ),
        }}
        sx={{ mt: 2 }}
      />
      <AppDrawer open={isAddSupplierModalOpen} onClose={toggleAddSupplierModal} width="92vw">
        <AddSessionSupplierInvoice selectedIds={selection as number[]} />
      </AppDrawer>
      <AppDrawer open={isXeroModalOpen} onClose={toggleXeroModal} width="92vw">
        <GenerateSessionXeroInvoices selectedIds={selection as number[]} />
      </AppDrawer>
      <AppDrawer open={isSendEInvoiceModalOpen} onClose={toggleSendEInvoiceModal} width="92vw">
        <SendSessionEInvoices selectedIds={selection as number[]} />
      </AppDrawer>
    </AppPaper>
  );
};
