import React, { SyntheticEvent, useState } from 'react';
import { Button, Tooltip } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CheckIcon from '@mui/icons-material/Check';

type Props = {
  textToCopy: string;
  tooltipText?: string;
};

const ShowCopiedTimeoutMs = 5000;

export const CopyToClipboard: React.FC<Props> = ({ textToCopy, tooltipText }) => {
  const [isCopied, setIsCopied] = useState(false);

  const copyToClipboard = async (event: SyntheticEvent): Promise<void> => {
    event.stopPropagation();
    try {
      await navigator.clipboard.writeText(textToCopy);
      setIsCopied(true);

      setTimeout(() => {
        setIsCopied(false);
      }, ShowCopiedTimeoutMs);
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  };

  const buttonLabel = isCopied ? 'Copied!' : 'Copy to clipboard';
  const buttonIcon = isCopied ? <CheckIcon /> : <ContentCopyIcon />;

  function renderButton(): JSX.Element {
    return (
      <Button onClick={copyToClipboard} sx={{ minWidth: '200px' }} size="small" startIcon={buttonIcon}>
        {buttonLabel}
      </Button>
    );
  }

  if (tooltipText) {
    return <Tooltip title={tooltipText}>{renderButton()}</Tooltip>;
  }

  return renderButton();
};
