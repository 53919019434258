import { Routes } from '@routes/routes';
import { ReportLink, reportNavigation } from './report-navigation';

const createRedirectMap = (links: ReportLink[]): Record<string, string> => {
  const redirectMap: Record<string, string> = {};

  const processLinks = (linkList: ReportLink[]): void => {
    linkList.forEach((link) => {
      if (link.sections.length > 0) {
        redirectMap[link.slug] = `${Routes.report}/${link.sections[0].slug}`;

        processLinks(link.sections);
      }
    });
  };

  processLinks(links);

  return redirectMap;
};

export const redirectMap = createRedirectMap(reportNavigation);
