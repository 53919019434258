import { useEffect } from 'react';
import { useGetReportTemplateQuery } from '@api/api-slice';
import { useAppDispatch } from '@store/use-app-dispatch';
import { useAppSelector } from '@store/use-app-selector';
import { RequestStatusFlags } from '@utils/merge-request-status-flags';
import { ReportTemplateActions, selectTemplateBySlug } from './report-template.slice';
import { IReportTemplatePage } from './report-template-page.interface';

type HookResult = RequestStatusFlags & { template: IReportTemplatePage | null };

export function useReportTemplate(slug: string): HookResult {
  const { data, ...flags } = useGetReportTemplateQuery(704);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (data) {
      dispatch(ReportTemplateActions.upsertMany(data));
    }
  }, [data, dispatch]);

  const template = useAppSelector((state) => selectTemplateBySlug(state, slug)) ?? null;

  return { template, ...flags };
}
