import { appQueryFactory } from '@api/api-slice';
import { EndpointUrlBuilder } from '@api/endpoints';
import { IReportTemplatePage } from './report-template-page.interface';

type Response = {
  'Student Finance England': IReportTemplatePage[];
};

export const buildGetReportTemplate = appQueryFactory<number, IReportTemplatePage[]>({
  query: (orderId) => ({
    url: EndpointUrlBuilder.getReportTemplate(orderId),
    method: 'GET',
  }),
  transformResponse(response: Response) {
    return response['Student Finance England'];
  },
});
