import React, { useEffect } from 'react';
import { AppPaper } from '@components/AppPaper';
import { ReportNavigation } from './ReportNavigation';
import { Box, Stack } from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import { RouteParamsType } from '@routes/route-params.type';
import { redirectMap } from './redirect-map';
import { ReportSection } from './ReportSection';

const minWidth = '320px';

export const ReportBuilderPage: React.FunctionComponent = () => {
  const { reportSlugString } = useParams<RouteParamsType>();
  const navigate = useNavigate();

  useEffect(() => {
    if (reportSlugString && reportSlugString in redirectMap) {
      navigate(redirectMap[reportSlugString]);
    }
  }, [navigate, reportSlugString]);

  return (
    <AppPaper>
      <Stack direction="row" sx={{ height: '100%' }}>
        <Box sx={{ flexBasis: minWidth, minWidth }}>
          <ReportNavigation />
        </Box>
        <Box sx={{ flexGrow: 1, height: '100%' }}>
          <ReportSection />
        </Box>
      </Stack>
    </AppPaper>
  );
};
