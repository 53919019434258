import { IReportTemplatePage } from './report-template-page.interface';
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { RootState } from '@store/store';

const reportTemplateAdapter = createEntityAdapter<IReportTemplatePage>({
  selectId: (entry) => entry.slug,
});

const reportTemplateSlice = createSlice({
  name: 'ReportTemplate',
  initialState: reportTemplateAdapter.getInitialState(),
  reducers: {
    upsertMany: reportTemplateAdapter.upsertMany,
  },
});

export const ReportTemplateActions = reportTemplateSlice.actions;
export const reportTemplateReducer = reportTemplateSlice.reducer;
export type ReportTemplateActionsType = typeof ReportTemplateActions;

export const { selectAll, selectById: selectTemplateBySlug } = reportTemplateAdapter.getSelectors(
  (state: RootState) => state.reportTemplates,
);
