import { GridColDef, GridRowParams } from '@mui/x-data-grid';
import React, { useState } from 'react';
import { useAssessorFeedbackSummaries } from './use-assessor-feedback-summaries';
import { formatDate } from '@utils/dates/format-date';
import { Alert, Typography } from '@mui/material';
import { createColumnFactory } from '@utils/create-column.factory';
import { IAssessorFeedbackSummary } from './assessor-feedback-summary.interface';
import { AppDataGrid } from '@components/AppDataGrid';
import { AppModal } from '@components/AppModal';
import { usePagination } from '@features/pagination/use-pagination';
import { PageSizeNames } from '@features/pagination/page-size-names';

const createFeedbackSummaryColumn = createColumnFactory<IAssessorFeedbackSummary>();

const columns: GridColDef[] = [
  createFeedbackSummaryColumn('id', { headerName: 'Id', width: 60 }),
  createFeedbackSummaryColumn('feedbackDate', {
    headerName: 'Date',
    width: 90,
    renderCell: ({ row }) => formatDate(row.feedbackDate),
  }),
  createFeedbackSummaryColumn('feedbackScore', { headerName: 'Score', width: 60 }),
  createFeedbackSummaryColumn('sessionId', { headerName: 'Appointment Id', width: 90 }),
  createFeedbackSummaryColumn('questions', {
    headerName: 'Ratings',
    width: 90,
    renderCell: ({ row }) => row.questions.map(({ rate }) => rate).join(', '),
  }),
  createFeedbackSummaryColumn('openComments', { headerName: 'Comments', flex: 1 }),
];

export const AssessorFeedback: React.FC = () => {
  const {
    entries: feedbacks,
    totalEntries,
    paginationModel,
    onPaginationModelChange,
    isLoading,
  } = usePagination(useAssessorFeedbackSummaries, PageSizeNames.AssessorFeedback);

  const [currentFeedback, setCurrentFeedback] = useState<IAssessorFeedbackSummary | null>(null);

  const openFeedback = ({ row }: GridRowParams<IAssessorFeedbackSummary>): void => setCurrentFeedback(row);
  const closeFeedback = (): void => setCurrentFeedback(null);
  const feedbackTitle = currentFeedback
    ? `Score: ${currentFeedback.feedbackScore}, Date: ${formatDate(currentFeedback.feedbackDate, 'DD/MM/YYYY HH:mm')}`
    : '';

  if (!feedbacks) {
    return <Alert severity="warning">Feedback not found.</Alert>;
  }

  return (
    <>
      <Typography variant="h4" component="h1" sx={{ mt: 4 }}>
        Feedback Summaries
      </Typography>
      <AppDataGrid
        rows={feedbacks}
        columns={columns}
        loading={isLoading}
        sx={{ mt: 1 }}
        hideFooter={false}
        paginationMode="server"
        rowCount={totalEntries}
        paginationModel={paginationModel}
        onPaginationModelChange={onPaginationModelChange}
        onRowClick={openFeedback}
        fullHeight
      />
      <AppModal open={Boolean(currentFeedback)} onClose={closeFeedback} title={feedbackTitle}>
        <Typography>{currentFeedback?.openComments}</Typography>
      </AppModal>
    </>
  );
};
