import React from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { ReportLink, reportNavigation } from './report-navigation';
import { Routes } from '@routes/routes';

type Props = {
  link: ReportLink;
};

const isLinkActive = (link: ReportLink, currentPath: string): boolean => {
  const isCurrent = currentPath.includes(link.slug);
  return isCurrent || link.sections.some((subLink) => isLinkActive(subLink, currentPath));
};

export const ReportNavigationLink: React.FunctionComponent<Props> = ({ link }) => {
  const location = useLocation();
  const currentPath = location.pathname;

  const hasLinkActive = isLinkActive(link, currentPath);
  const hasSections = link.sections.length > 0;

  return (
    <li>
      <NavLink to={`${Routes.report}/${link.slug}`} className="report-navigation-link">
        {link.title}
      </NavLink>
      {hasLinkActive && hasSections && (
        <ul className="report-navigation report-navigation--nested">
          {link.sections.map((link, index) => (
            <ReportNavigationLink link={link} key={`${link.slug}-${index}`} />
          ))}
        </ul>
      )}
    </li>
  );
};

export const ReportNavigation: React.FC = () => {
  return (
    <ul className="report-navigation">
      {reportNavigation.map((link, index) => (
        <ReportNavigationLink link={link} key={`${link.slug}-${index}`} />
      ))}
    </ul>
  );
};
