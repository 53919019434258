import { AppBackendLabels } from '@features/backend-label/backend-label.type';
import { GridColDef, GridValidRowModel } from '@mui/x-data-grid';
import { EmptyCell } from '@utils/empty-cell';

const DefaultColumnConfig: Partial<GridColDef> = {
  disableColumnMenu: true,
  hideSortIcons: true,
};

type BackendLabelKey = { optionKey?: keyof AppBackendLabels };
type LabeledGridColDef = GridColDef & BackendLabelKey;

/** String for key not exist in model, but needed for extra action column */
export const ActionColumnKey = 'actionColumnKey';

/**
 * accepts model for inferring GridColDef types
 * @template T
 * @returns createGenericColumn function accepts T[fieldName] and GridColDef config
 */
export function createColumnFactory<T extends GridValidRowModel>(config: Partial<GridColDef> = DefaultColumnConfig) {
  return function createGenericColumn<K extends keyof T | typeof ActionColumnKey>(
    field: K,
    colDef: Partial<GridColDef<T, T[K]>> & BackendLabelKey,
  ): LabeledGridColDef {
    return {
      ...config,
      renderCell: ({ row }) => row[field] ?? EmptyCell,
      ...colDef,
      field: field.toString(),
    };
  };
}
