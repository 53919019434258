import React, { PropsWithChildren, useEffect, useState } from 'react';
import { isMatch } from 'lodash';
import { Link, useSearchParams } from 'react-router-dom';
import { Badge, Box, Button, CircularProgress, Stack, Tab, Tabs } from '@mui/material';
import { stringifyObjectFields } from '@utils/stringify-object-fields';
import { filterEmptyObjectFields } from '@utils/filter-empty-object-fields';
import { useToggle } from '@hooks/use-toggle';
import { AllTab, useQuickFilterTabs } from './use-quick-filter-tabs';
import { QuickFilterTabsType } from './quick-filter-tabs.type';

type Props = PropsWithChildren<{
  onChange: React.Dispatch<React.SetStateAction<boolean>>;
  type: QuickFilterTabsType;
}>;

export const QuickFilters: React.FC<Props> = ({ onChange, type, children }) => {
  const [currentTab, setCurrentTab] = useState<number | null>(null);
  const [filterCount, setFilterCount] = useState(0);
  const [isFiltersVisible, toggleFiltersVisible] = useToggle(false);
  const { tabs, isLoading } = useQuickFilterTabs(type);
  const [params] = useSearchParams();

  const handleClick = (event: React.SyntheticEvent): void => {
    event.stopPropagation();
  };

  useEffect(() => {
    setFilterCount(params.size);
    if (params.size === 0) {
      setCurrentTab(AllTab.id);
      onChange(AllTab.hasBulkSending);
    } else {
      const matchedTab = tabs
        .filter(({ id }) => id > 0)
        .find(({ fields }) =>
          isMatch(Object.fromEntries(params), stringifyObjectFields(filterEmptyObjectFields(fields))),
        );
      setCurrentTab(matchedTab?.id ?? null);
      onChange(matchedTab?.hasBulkSending ?? false);
    }
  }, [onChange, params, tabs]);

  if (isLoading) {
    return <CircularProgress sx={{ margin: 'auto' }} />;
  }

  return (
    <Box>
      <Stack direction="row" alignItems="center">
        <Tabs value={currentTab}>
          {tabs.map((tab) => (
            <Tab
              key={tab.id}
              value={tab.id}
              to={`?${new URLSearchParams(stringifyObjectFields(tab.fields)).toString()}`}
              component={Link}
              label={tab.name}
              onClick={handleClick}
            ></Tab>
          ))}
        </Tabs>
        {children && (
          <Badge badgeContent={filterCount} color="primary" sx={{ marginLeft: 'auto' }}>
            <Button onClick={toggleFiltersVisible} size="small" sx={{ minWidth: 130 }} variant="outlined">
              {isFiltersVisible ? 'Hide Filters' : 'Show Filters'}
            </Button>
          </Badge>
        )}
      </Stack>
      {children && isFiltersVisible && <Box sx={{ mt: 1 }}>{children}</Box>}
    </Box>
  );
};
