import { redirectMap } from './redirect-map';
import { ReportLink, reportNavigation } from './report-navigation';

type NavigationMap = {
  [slug: string]: {
    prev: string | null;
    next: string | null;
    title: string;
  };
};

// Helper function to create the navigation map
const createNavigationMap = (links: ReportLink[]): NavigationMap => {
  const navigationMap: NavigationMap = {};
  let previousSlug: string | null = null;

  // Recursive function to walk through all sections and sub-sections
  const processLinks = (linkList: ReportLink[]): void => {
    for (const link of linkList) {
      const currentSlug = link.slug;

      if (!(currentSlug in redirectMap)) {
        // Set the current slug's prev field
        navigationMap[currentSlug] = {
          prev: previousSlug, // Set the previous slug (or null if no previous)
          next: null, // The next will be updated later when we know it
          title: link.title,
        };

        // Update the previous slug to the current one for the next iteration
        if (previousSlug) {
          navigationMap[previousSlug].next = currentSlug; // Link previous slug's next to the current slug
        }

        previousSlug = currentSlug; // Move the pointer forward
      }

      // Process sub-sections recursively
      if (link.sections.length > 0) {
        processLinks(link.sections);
      }
    }
  };

  processLinks(links);

  return navigationMap;
};

export const navigationMap = createNavigationMap(reportNavigation);
